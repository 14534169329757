<div
  class="w-100 pt-1"
  *ngIf="taskList.length > 0"
  [formGroup]="formTaskFilter"
>
  <div
    *ngFor="let task of taskLevel; let taskIndex=index"
    formArrayName="tasks"
    class="pb-2 d-flex flex-column justify-content-center align-items-start"
  >
    <div class="d-flex justify-content-start align-items-center pb-1">
      <div *ngFor="let level of [].constructor(task?.level || 0)" style="width: 10px"></div>
      <div class="d-flex justify-content-start align-items-center">
        <input
          *ngIf="task.type !== taskType.GROUP"
          class="pointer me-2"
          type="checkbox"
          id="task-{{taskIndex}}"
          [formControlName]="taskIndex"
        >
        <span
          *ngIf="task.type === taskType.GROUP"
          class="rounded-circle d-flex justify-content-center align-items-center me-2"
          [ngStyle]="{
          'width': '12px',
          'height': '12px',
          'border': '1px solid var(--complementary-color-a)',
          'background-color': 'var(--complementary-color-a)'
        }"
          style="width: 12px; height: 12px"
        ></span>
        <span class="text-normal fw-semibold user-select-none">
          {{ task.label }}
        </span>
      </div>
    </div>

  </div>
</div>

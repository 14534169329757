<div
  class="h-100 w-100 overflow-y-hidden d-flex flex-column"

>
  <!-- button bar -->
  <div
    class="w-100 flex-shrink-0 d-flex justify-content-center align-items-center px-3"
    style="height: 50px"
  >
    <div class="flex-grow-1">
     <!-- To use new bar content -->
    </div>
    <div class="">
      <!-- Add button -->
      <div
        class="rounded-1 px-2 py-1 bg-primary-color d-flex justify-content-center align-items-center pointer"
        style="height: 28px"
        (click)="addEmailAccount()"
      >
        <div
          class="text-white me-sm-1" role="status"
        ></div>
        <span
          class="material-symbols-outlined text-title text-white"
        >
         add
        </span>
        <span class="text-normal text-white d-none d-sm-inline">
         Add account
        </span>
        <span class="text-normal text-white d-inline d-sm-none">
         Add
        </span>
      </div>
    </div>



  </div>

  <div
    class="w-100 flex-grow-1 overflow-y-auto px-3 pb-2 d-flex flex-column gap-2"
  >
    <!-- Skeleton -->
    <ng-container *ngIf="loadingSettings">
      <div
        class="border rounded-2 d-flex align-items-center email-provider-card
              hover:bg-neutral-color-f pointer"
        style="height: 50px"
        *ngFor="let i of [].constructor(3), let index=index"
      >
        <div
          class="skeleton-box rounded-circle mx-2"
          style="height: 42px; width: 42px"
        >
        </div>
        <div
          class="flex-grow-1 d-flex flex-column gap-1 justify-content-center  align-items-start"
        >
          <div class="skeleton-box rounded-pill name-skeleton" style="height: 14px;"></div>
          <div class="skeleton-box rounded-pill email-skeleton" style="height: 12px;"></div>
        </div>
      </div>

    </ng-container>

    <!-- Empty Data -->
    <ng-container *ngIf="userEmailSettings.length === 0 && !loadingSettings">
      <div
        class="w-100 h-100 d-flex flex-column justify-content-center align-items-center"
      >
        <div>
          <img src="assets/img/no-email-provider.png">
        </div>
        <div
          class="w-25 d-flex justify-content-center text-center"
        >
          <span class="text-neutral-color-g text-sub-title">
            You don't have any email providers connected yet. Add a new one to your account using the <b>"Add"</b> button at the top.
          </span>
        </div>
      </div>

    </ng-container>

    <!-- Accounts -->
    <ng-container *ngIf="userEmailSettings.length > 0 && !loadingSettings">
      <div
        class="position-relative border rounded-2 d-flex align-items-center email-provider-card
              hover:bg-neutral-color-f pointer"
        style="height: 50px"
        *ngFor="let userSetting of userEmailSettings, let index=index"
      >
        <div
          *ngIf="userSetting.provider.icon_url"
          class="h-100 px-2 py-2 d-flex justify-content-center align-items-center"
        >
          <img src="{{userSetting.provider.icon_url}}" alt="Google" height="100%">
        </div>
        <div
          class="flex-grow-1 d-flex flex-column flex-sm-row justify-content-center align-items-start
                justify-content-sm-start  align-items-sm-center"
        >
          <span class="text-sub-title fw-bold text-complementary-color-a">
            {{userSetting.account_name}}
          </span>
          <span class="text-sub-title px-sm-1">
            {{userSetting.email}}: {{userSetting.status}}
          </span>
        </div>
        <div
          class="top-0 end-0 px-2 position-absolute justify-content-center align-items-center
               gap-1 h-100 email-provider-actions"
        >
        <span
          class="material-symbols-outlined text-title pointer p-1 user-select-none"
          tooltip="Edit"
          containerClass="text-mini fw-bold"
          (click)="editEmailAccount(userSetting, index)"
        >
          edit_square
        </span>

          <!-- Remove Process Result Action -->
          <span
            class="material-symbols-outlined text-title p-1 pointer user-select-none"
            tooltip="Delete"
            containerClass="text-mini fw-bold"
            (click)="removeEmailAccount(userSetting, index)"
          >
          delete
        </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

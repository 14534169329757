import {Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";
import {Store} from '@ngrx/store';
import {combineLatest, filter, Subject, Subscription, takeUntil} from 'rxjs';
import {ProcessScrollI} from '../../../infrastructure/interfaces/process-scroll.interface';
import {Router} from '@angular/router';
import {selectSelectedProcess} from '../../../infrastructure/store/process/process.selector';
import {
  selectDetailsProcessResult,
  selectProcessResultFilter, selectProcessResultGlobalSearch,
  selectProcessResultMap
} from '../../../infrastructure/store/process-result/process-result.selector';
import {
  MapResultI,
  ProcessResultFilterI,
  ProcessResultI,
  ViewComponentType
} from '../../../infrastructure/interfaces/process-result.interface';
import {DetailTabs, TaskStatus, TaskType} from '../../../infrastructure/interfaces/task.interface';
import {selectMapElementsLimit, selectMapProperties} from '../../../infrastructure/store/config/config.selector';
import {MapClusterProperties, MapCoordsI,} from "../../../infrastructure/interfaces/map-process.interface";
import {ProcessI} from "../../../infrastructure/interfaces/process.interface";
import {
  loadProcessResultMap,
  resetProcessResultMap,
  setDetailProcessResult,
  setLoadingDetailProcessResult, setLoadingProcessResultMap,
  setResultDetailsTab,
  setShowRightBar,
  setViewComponentProcessResult
} from "../../../infrastructure/store/process-result/process-result.actions";
import {ProcessResultService} from "../../../infrastructure/services/process-result.service";

declare var google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild("map", { static: true }) mapElement!: ElementRef;

  private destroy$ = new Subject<void>();

  map: any;
  cluster!: MarkerClusterer;
  markersProcess: MapResultI[] = [];
  markers: any[] = [];
  infoWindow: any;
  markerClustererProperties!: MapClusterProperties

  loadedMapPages: number = 0;
  loadNextPage$ = new Subject<number>();
  shortCircuitLoadNextPage$ = new Subject<void>();
  dataSubscription!: Subscription;

  process: ProcessI | null = null;
  processList: ProcessScrollI[] = [];

  mapElementsLimit: number = 1000;

  processResultDetail: ProcessResultI | null = null;

  processResultGlobalSearch!: string;
  processResultFilter!: ProcessResultFilterI;

  styles: Record<string, google.maps.MapTypeStyle[]> = {
    default: [],
    hide: [
      {
        featureType: "all",
        elementType: "all",
        stylers: [{ visibility: "off" }],
      }
    ],
  };

  mapProperties = {
    center: { lat: 36.33442548286127, lng: -80.06837577819276 },
    zoom: 4,
    // mapId: '3d7f3297d201aec2',
    minZoom: 2,
    maxZoom: 18,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    fullscreenControl: false,
    dataTapDisabled: false
  };

  constructor(
    private store: Store,
    private router: Router,
    private zone: NgZone,
    private processResultService: ProcessResultService
  ) { }

  ngOnInit(): void {
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    // this.map.setOptions({ styles: this.styles["hide"] })
    this.infoWindow = new google.maps.InfoWindow();

    // listen for config changes
    this.store.select(selectMapProperties).pipe(
      takeUntil(this.destroy$),
    ).subscribe((value) => {
      this.markerClustererProperties = value.markerClusterer;
    })

    // listen for process changes
    combineLatest([
      this.store.select(selectProcessResultFilter),
      this.store.select(selectProcessResultGlobalSearch),
      this.store.select(selectSelectedProcess),
      this.store.select(selectMapElementsLimit)
    ])
    .pipe(
      takeUntil(this.destroy$),
      filter(([processResultFilter, processResultGlobalSearch, process, mapElementsLimit]) => process != null)
    ).subscribe(([processResultFilter, processResultGlobalSearch, process, mapElementsLimit]) => {
      this.process = process;
      this.processResultGlobalSearch = processResultGlobalSearch;
      this.processResultFilter = processResultFilter;
      this.mapElementsLimit = mapElementsLimit

      if (this.process){
        this.cluster ? this.cluster.setMap(null): null;
        if (this.markers?.length > 0){
          this.markers.forEach((marker) => marker.setMap(null));
        }
        this.markers = [];
        this.store.dispatch(resetProcessResultMap());


        // Using timeout waiting for loadNextPage subscription
        setTimeout(()=>{
          this.loadNextPage$.next(1)
        }, 11)
      }
    })

    this.store.select(selectProcessResultMap).pipe(
      takeUntil(this.destroy$)
    ).subscribe(async (data) => {
      if (data.result.length > 0) {
        if (data.loaded === data.count){
          this.markersProcess = [...this.markersProcess, ...data.result[data.page - 1]]
          await this.findLocation(data.result[data.page - 1 ]);
          this.store.dispatch(setLoadingProcessResultMap({status: false}))
          return
        }
        if (data.loaded < data.count && data.result.length === data.page){
          this.markersProcess = [...this.markersProcess, ...data.result[data.page - 1]]
          this.loadNextPage$.next(data.page + 1)
          await this.findLocation(data.result[data.page - 1 ]);
        }
      }
    })


    this.loadNextPage$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((page) => {
      if (this.process){

        const {tasks:_, bookmarked:__, process_status:___, ...filter} = this.processResultFilter;

        this.store.dispatch(setLoadingProcessResultMap({status: true}));

        this.store.dispatch(loadProcessResultMap({
          processId: this.process.id,
          page: page,
          limit: this.mapElementsLimit,
          bookmarked: this.processResultFilter.bookmarked,
          process_status: this.processResultFilter.process_status,
          tasks: this.processResultFilter.tasks,
          filter: filter,
          globalSearch: this.processResultGlobalSearch,
          reset: true
        }))
      }
    })

    //listen for processResultDetails changes
    this.store.select(selectDetailsProcessResult).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.processResultDetail = value;
    })
  }

  mapZoom(direction: "in" | "out") {
    if (direction === "in"){
      this.map.setZoom(this.map.getZoom() + 1);
    }
    if (direction === "out"){
      this.map.setZoom(this.map.getZoom() - 1);
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(resetProcessResultMap())
    this.markers = [];
    this.cluster ? this.cluster.setMap(null): null;
    console.log("DESTROY MAP ELEMENT")
  }

  async findLocation(process: MapResultI[]) {
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

    const clean_process = process.filter((val) => val.lat && val.lng )

    const markers = clean_process.map((result) => {
      const marker = this.createMarker({
        lat:  +`${result.lat}`,
        lng: +`${result.lng}`,
        placeId: `${result.id}`,
        processResult: result,
      })
      return marker
    })

    // const geocodePromises = clean_process.map((element, index) => {
    //   const element_index = this.markersProcess.findIndex((val) => element.id === val.id)
    //   if (element_index === -1) {
    //     if (element.mapData?.lat && element.mapData?.lng){
    //       return new Promise<void>((resolve, reject) => {
    //         this.markers.push(
    //           this.createMarker({
    //             lat:  +`${element.mapData.lat}`,
    //             lng: +`${element.mapData.lng}`,
    //             placeId: `${element.id}`,
    //             processResult: element,
    //           })
    //         )
    //         this.markersProcess.push(element);
    //         resolve();
    //       })
    //     }
    //     else{
    //       return new Promise<void>((resolve, reject) => {
    //         const address = element.mapData.address;
    //         const geocoder = new google.maps.Geocoder();
    //         geocoder.geocode({ address: address }, (results: any, status: any) => {
    //           if (status === 'OK') {
    //             this.markers.push(
    //               this.createMarker({
    //                 lat: results[0].geometry.location.lat(),
    //                 lng: results[0].geometry.location.lng(),
    //                 placeId: results[0].place_id,
    //                 processResult: element,
    //               })
    //             )
    //             this.markersProcess.push(element);
    //             resolve();
    //           } else {
    //             console.log(`Address not found: '${address}'`);
    //             resolve();
    //           }
    //         });
    //       });
    //     }
    //   }
    //   return new Promise<void>((resolve, reject) => resolve())
    //
    // });

    // await Promise.all(geocodePromises);
    if (this.cluster){
      this.cluster.setMap(null);
    }
    markers.forEach((marker:any) => {
      marker.setMap(this.map);
    })
    this.markers = [...this.markers, ...markers];
    this.createMarkerClusters(this.markers);
  }

  createMarker(data: MapCoordsI) {
    const label = `${Math.round(+data.processResult.percentComplete)}%`;
    const svg = `
      <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="${data.processResult.color}" />
        <text x="20" y="25" font-size="13" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">
            ${label}
        </text>
      </svg>
      `;

    const encodedSvg = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg.trim());
    const markerSize = 36;
    const marker = new google.maps.Marker({
      position: {
        lat: data.lat,
        lng: data.lng,
        placeId: data.placeId
      },
      icon: {
        url: encodedSvg,
        scaledSize: new google.maps.Size(markerSize, markerSize)
      }
    });

    // marker.setMap(this.map);

    marker.addListener('click', () => {
      this.zone.run(async ()=>{
        this.store.dispatch(setResultDetailsTab({
          tab: DetailTabs.info
        }));
        this.store.dispatch(setShowRightBar({
          status: true
        }));
        if (data.processResult.id === this.processResultDetail?.id){
          this.store.dispatch(setResultDetailsTab({
            tab: DetailTabs.info
          }));
          this.store.dispatch(setShowRightBar({
            status: true
          }));
          this.buildPlaceDetails(this.processResultDetail, marker);
        } else{
          this.skeletonPlaceDetails(marker);
          this.store.dispatch(setLoadingDetailProcessResult({
            status: true
          }));
          this.processResultService.getProcessResult({
            processId: this.process?.id || '',
            resultId: data.processResult.id
          }).pipe(
            takeUntil(this.destroy$)
          ).subscribe((value) => {
            this.store.dispatch(setLoadingDetailProcessResult({
              status: false
            }));
            this.store.dispatch(setDetailProcessResult({
              result: value
            }));

            this.buildPlaceDetails(value, marker);
          })
        }
      })
    });
    return marker;
  }

  skeletonPlaceDetails(marker: any) {
    const process_status =  (this.process?.tasks || [])
      .filter((val) => val.type === 'GROUP')
      .map((val) => {
        return `
        <div class="info-status-dot rounded-circle skeleton-box" style="width: 15px; height: 15px; margin-right: 5px;"></div>
        `
      })
    const contentString = `
        <div class="info-window p-1 bg-white rounded shadow-sm overflow-hidden" style="width: 230px;">
          <div class="info-header d-flex flex-column justify-content-center pb-3">
            <div class="info-icon mr-2"></div>
            <div class="skeleton-box rounded-1 mb-1" style="height: 12px"></div>
            <div class="skeleton-box rounded-1" style="height: 12px"></div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="info-link">
                <div class="info-status d-flex mb-3">
                  ${process_status.join(" ")}
                </div>
                <a class="skeleton-box rounded-1" style="height: 12px; width: 87px">
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="info-progress d-flex justify-content-end">
                <div class="info-progress-circle rounded-circle position-relative skeleton-box" style="width: 45px; height: 45px;">

                </div>
              </div>
            </div>
          </div>
        </div>
        `;

    this.infoWindow.setContent(contentString);
    this.infoWindow.open(this.map, marker);
  }

  buildPlaceDetails(result: ProcessResultI, marker: any) {
    const first_draft_task = result.tasks.filter((val) => val.status.key === "draft" && val.type === 'GROUP')[0]

    const process_status =  result.tasks
      .filter((val) => val.type === 'GROUP')
      .map((val) => {
        let color = '#DADADA';
        switch (val.status.key) {
          case TaskStatus.draft:
            color = '#EDDD54';
            break;
          case TaskStatus.complete:
            color = '#7ee882';
            break;
          default:
            color = '#DADADA';
            break;
        }
        return `
        <div class="info-status-dot rounded-circle" style="width: 15px; height: 15px; margin-right: 5px; background: ${color}"></div>
        `
      })

    const contentString = `
        <div class="info-window p-1 bg-white rounded shadow-sm overflow-hidden" style="width: 230px;">
          <div class="info-header d-flex flex-column justify-content-center pb-3">
            <div class="info-icon mr-2"></div>
            <div class="info-name fw-bold text-normal pb-1">${result.properties.title}</div>
            <div class="info-name ${result.properties.title ? 'text-mini': 'fw-bold text-normal'}">
                ${result.mapData.address}
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <div class="info-link">
                <div class="info-status d-flex mb-3">
                  ${process_status.join(" ")}
                </div>
                <a class="text-decoration-none view-details text-normal text-primary-color fw-bold" style="cursor: pointer">
                    Open Process ➔
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="info-progress d-flex justify-content-end">
                <div class="info-progress-circle position-relative" style="width: 45px; height: 45px;">
                  <svg viewBox="0 0 36 36" class="w-100 h-100">
                    <path
                      class="circle-bg"
                      d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      stroke="#eee"
                      stroke-width="3.8"
                      fill="none"
                    />
                    <path
                      class="circle"
                      d="M18 2.0845
                          a 15.9155 15.9155 0 0 1 0 31.831
                          a 15.9155 15.9155 0 0 1 0 -31.831"
                      stroke="${first_draft_task.color}"
                      stroke-width="3.8"
                      fill="none"
                      stroke-linecap="round"
                      style="stroke-dasharray: 100; stroke-dashoffset:  ${Math.round(100-result.percentComplete)};"
                      class="circle-animation"
                    />
                    <text x="18" y="20.35" class="percentage text-center" fill="#000000" font-size="0.5em" font-weight="700" text-anchor="middle">${Math.round(result.percentComplete)}%</text>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        `;

    this.infoWindow.setContent(contentString);
    this.infoWindow.open(this.map, marker);

    google.maps.event.addListener(this.infoWindow, 'domready', () => {
      const element =  document.querySelector('.view-details')
      element?.addEventListener('click', () => {
        this.openProcess(result, element)
      });
    });
  }

  // putMarketAsSelected(data: MapCoordsI, marker: any) {
  //   this.markers.forEach((mark, index) => {
  //     const first_task_draft = this.markersProcess[index].tasks.filter((val) => val.status?.key === "draft" && val.type === 'GROUP')[0]
  //
  //     const label = `${Math.round(this.markersProcess[index].percentComplete)}%`;
  //     const svg = `
  //     <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
  //       <circle cx="20" cy="20" r="20" fill="${first_task_draft.color}" />
  //       <text x="20" y="25" font-size="13" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">
  //           ${label}
  //       </text>
  //     </svg>
  //     `;
  //
  //     const encodedSvg = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg.trim());
  //     const markerSize = 36;
  //     const icon = {
  //       url: encodedSvg,
  //       scaledSize: new google.maps.Size(markerSize, markerSize)
  //     }
  //     mark.setIcon(icon);
  //   })
  //
  //   const first_task_draft = data.processResult.tasks.filter((val) => val.status.key === "draft" && val.type === 'GROUP')[0]
  //   const label = `${Math.round(data.processResult.percentComplete)}%`;
  //   const svg = `
  //   <svg width="90" height="90"  xmlns="http://www.w3.org/2000/svg">
  //     <circle cx="45" cy="45" r="42" stroke="#346461" stroke-width="5" fill="none" />
  //     <circle cx="45" cy="45" r="40" fill="${first_task_draft.color}" />
  //     <text x="45" y="55" font-size="26" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">
  //         ${label}
  //     </text>
  //   </svg>
  //   `;
  //
  //   const encodedSvg = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg.trim());
  //   const markerSize = 36;
  //   const icon = {
  //     url: encodedSvg,
  //     scaledSize: new google.maps.Size(markerSize, markerSize)
  //   }
  //   marker.setIcon(icon);
  //
  // }


  createMarkerClusters(markers: any) {
    const clusterStyles = [
      {
        color: this.markerClustererProperties.color || "#75E67A",
        baseSize: this.markerClustererProperties.baseSize || 30,
        baseTextSize:  this.markerClustererProperties.baseTextSize || 12,
        fontColor: this.markerClustererProperties.fontColor || "#000000"
      },
    ];

    this.cluster = new MarkerClusterer({
      map: this.map,
      markers: markers,
      algorithm: new SuperClusterAlgorithm({ maxZoom: 10 }),
      renderer: {
        render: ({ count, position }) => {
          const scaleFactor = Math.log(count) / Math.log(2);
          const style = clusterStyles[0];
          const size = style.baseSize + scaleFactor * 5;
          const textSize = style.baseTextSize + scaleFactor * 2;
          const svg = `
          <svg width="${size}" height="${size}" xmlns="http://www.w3.org/2000/svg">
            <circle cx="${size / 2}" cy="${size / 2}" r="${size / 2}" fill="${style.color}"/>
            <text x="${size / 2}" y="${size / 2}" font-size="${textSize}" font-family="Arial" text-anchor="middle" fill="${style.fontColor}" dy=".3em">${count}</text>
          </svg>
          `;
          const encodedSvg = 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svg.trim());
          return new google.maps.Marker({
            position,
            icon: {
              url: encodedSvg,
              scaledSize: new google.maps.Size(size, size)
            }
          });
        }
      }
    });
  }


  openProcess(processResult: ProcessResultI, element: Element) {
    if (this.process){

      this.store.dispatch(setViewComponentProcessResult({value: ViewComponentType.table}));

      //Get first draft task
      const tasks = processResult.tasks.filter((task) => {
        return task.type !== TaskType.GROUP && task.status.key === TaskStatus.draft
      })

      // Calc url to first draft task
      if (tasks.length > 0) {
        const url = `/process/${this.process?.id}/process-results/${processResult.id}/${tasks[0].id}`
        this.router.navigate([url]);
      }
    }
  }
}

<div class="h-100 d-flex flex-column">
  <div class="mx-3 d-flex justify-content-start align-items-center pt-3">
    <span class="text-normal pe-2" style="padding-bottom: 1px">
      Task Status:
    </span>
    <app-process-state
      [stateList]="process?.task_states?.states_list || []"
      [selectedState]="defaultState"
      [editable]="true"
      (onSelectState)="selectTaskStatus($event)"
    ></app-process-state>
  </div>
  <div
    *ngIf="!loadingProcessResultGridData"
    class="d-flex flex-grow-1 overflow-auto gap-3 px-3 py-3"
  >

    <div
      class="border p-3 rounded-2 grid-card"
      *ngFor="let task of gridDataTree; let index=index"
    >
      <!-- Card Image -->
      <div class="d-flex justify-content-center align-items-center pb-1">
        <img [src]="task?.picture" class="grid-card-image">
      </div>

      <!-- Card main element -->
      <div class="d-flex w-100 gap-2 grid-card-element">
        <div class="h-100 d-flex align-items-center">
          <div
            class="rounded-circle d-flex justify-content-center align-items-center"
            style="width: 20px; height: 20px"
            [ngStyle]="{
          'background-color': task?.color,
          'border': '1px solid ' + task?.color
          }"
          >
            <span class="text-white text-sub-title lh-1">{{index}}</span>
          </div>
        </div>
        <div class="flex-grow-1 d-flex align-items-center h-100">
          <span class="text-sub-title fw-bold lh-1">{{task?.label}}</span>
        </div>
        <div  class="d-flex align-items-center h-100">
        <span
          class="text-sub-title lh-1 pointer px-1 rounded-pill text-center align-middle hover:bg-neutral-color-c"
          [ngStyle]="{'color': task?.color}"
          (click)="show_elements(task)"
        >
          {{task?.total}}
        </span>
        </div>
      </div>

      <div *ngIf="(task?.children || []).length > 0" class="w-100 border-top"></div>

      <ng-container
        *ngFor="let child of task?.children || []; let taskLast=last; let taskFirst=first; let taskIndex=index"
      >
        <div class="grid-card-element">
          <div class="d-flex w-100 gap-2 h-100">
            <div class="h-100 d-flex align-items-center justify-content-center" style="width: 20px">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center"
                style="width: 12px; height: 12px"
                [ngStyle]="{
              'background-color': child?.color,
              'border': '1px solid ' + child?.color
              }"
              >
              </div>
            </div>
            <div class="flex-grow-1 d-flex align-items-center h-100">
              <span class="text-normal lh-1">{{child?.label}}</span>
            </div>
            <div class="d-flex align-items-center h-100">
          <span
            (click)="show_elements(child)"
            class="text-sub-title lh-1 pointer rounded-pill px-1 text-center align-middle hover:bg-neutral-color-c"
            [ngStyle]="{'color': child?.color}"
          >
            {{child?.total || 0}}
          </span>
            </div>
          </div>
        </div>

        <div *ngIf="!taskLast" class="w-100 border-top"></div>
      </ng-container>
    </div>
  </div>
  <!-- Skeleton -->
  <div *ngIf="loadingProcessResultGridData" class="d-flex overflow-auto h-100 px-3 py-3 gap-3">
    <div
      class="h-100 border p-3 rounded-2 grid-card"
      *ngFor="let task of gridDataTreeSkeleton; let index=index"
    >
      <!-- Card Image -->
      <div class="d-flex justify-content-center align-items-center pb-1">
        <div class="skeleton-box rounded-circle" style="width: 60px; height: 60px"></div>
      </div>

      <!-- Card main element skeleton-->
      <div class="d-flex w-100 gap-2 grid-card-element">
        <div class="h-100 d-flex align-items-center">
          <div
            class="rounded-circle d-flex justify-content-center align-items-center skeleton-box"
            style="width: 20px; height: 20px"
          >
          </div>
        </div>
        <div class="flex-grow-1 d-flex align-items-center h-100">
          <div class="skeleton-box w-100 rounded-2" style="height: 14px"></div>
        </div>
      </div>

      <div *ngIf="(task?.children || []).length > 0" class="w-100 border-top"></div>

      <ng-container
        *ngFor="let child of task?.children || []; let taskLast=last; let taskFirst=first; let taskIndex=index"
      >
        <div class="grid-card-element">
          <div class="d-flex w-100 gap-2 h-100">
            <div class="h-100 d-flex align-items-center justify-content-center" style="width: 20px">
              <div
                class="rounded-circle d-flex justify-content-center align-items-center skeleton-box"
                style="width: 12px; height: 12px"
              >
              </div>
            </div>
            <div class="flex-grow-1 d-flex align-items-center h-100">
              <div class="skeleton-box w-100 rounded-2" style="height: 12px"></div>
            </div>
          </div>
        </div>

        <div *ngIf="!taskLast" class="w-100 border-top"></div>
      </ng-container>
    </div>
  </div>
</div>





import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, filter, Subject, takeUntil, withLatestFrom} from "rxjs";
import {Store} from "@ngrx/store";
import {
  selectGridDefaultState,
  selectLoadingProcessResultGridData, selectProcessResultFilter,
  selectProcessResultGlobalSearch, selectProcessResultGridData
} from "../../../infrastructure/store/process-result/process-result.selector";
import {selectSelectedProcess} from "../../../infrastructure/store/process/process.selector";
import {
  loadProcessResultGridData, loadProcessResultList, setGridDefaultState,
  setLoadingProcessResultGridData, setLoadingResult, setProcessResultFilter, setViewComponentProcessResult
} from "../../../infrastructure/store/process-result/process-result.actions";
import {ProcessI, TaskStateI} from "../../../infrastructure/interfaces/process.interface";
import {ProcessResultService} from "../../../infrastructure/services/process-result.service";
import {TaskI, TaskType} from "../../../infrastructure/interfaces/task.interface";
import {ProcessResultFilterI, ViewComponentType} from "../../../infrastructure/interfaces/process-result.interface";
import {LocalStorageService} from "../../../infrastructure/services/local-storage.service";
import {initialProcessResultPaginator} from "../../../infrastructure/store/process-result/state";

@Component({
  selector: 'app-grid-global',
  templateUrl: './grid-global.component.html',
  styleUrls: ['./grid-global.component.scss']
})
export class GridGlobalComponent implements OnInit, OnDestroy{
  destroy$ = new Subject<void>();

  process: ProcessI | null = null;

  loadingProcessResultGridData: boolean = false

  gridData: TaskI[] = [];

  gridDataTree: TaskI[] = [];
  gridDataTreeSkeleton: TaskI[] = []

  globalSearch: string = "";
  processResultFilter!: ProcessResultFilterI

  defaultState!: TaskStateI

  constructor(
    private store: Store,
    private processResultService: ProcessResultService,
    private localStorageService: LocalStorageService
  ) {
  }

  ngOnInit() {
    // After load process result load grid data
    combineLatest([
      this.store.select(selectSelectedProcess),
      this.store.select(selectProcessResultGlobalSearch),
      this.store.select(selectGridDefaultState),
      this.store.select(selectProcessResultFilter)
    ]).pipe(
      takeUntil(this.destroy$),
      filter(([process, processResultGlobalSearch, defaultState, processResultFilter]) => process !== null)
    ).subscribe(([process, search, defaultState, processResultFilter]) => {
      this.process = process;
      this.globalSearch = search;
      this.processResultFilter = processResultFilter;
      if (process) {
        this.defaultState = defaultState ?? process.task_states.states_list.filter((v) => v.key === process.task_states.complete_state)[0];


        this.gridDataTreeSkeleton = this.processResultService.createTaskTree(process.tasks);

        //Load results of process
        const {tasks:_, bookmarked:__, process_status:___, ...filter} = processResultFilter;

        this.store.dispatch(setLoadingProcessResultGridData({status: true}));
        this.store.dispatch(loadProcessResultGridData({
          processId: process.id,
          search: search,
          status: this.defaultState.key,
          bookmarked: processResultFilter.bookmarked,
          process_status: processResultFilter.process_status,
          tasks: processResultFilter.tasks,
          filter: filter,
        }))
      }
    })

    //Listen for changes in loading process result grid data
    this.store.select(selectLoadingProcessResultGridData).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.loadingProcessResultGridData = value;
    })

    //Listen for changes in loading process result grid data
    this.store.select(selectProcessResultGridData).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      if (this.process) {
        this.gridData = value;
        this.gridDataTree = this.processResultService.createTaskTree(value)
      }

    })
  }

  show_elements(task: TaskI | null){
    if (this.process && task) {
      this.store.dispatch(setProcessResultFilter({
        filter: {
          ...this.processResultFilter,
          tasks: (task?.children || []).length > 0 ? (task.children || []).map((t) => t.id) : [task.id]
        }
      }))
      this.store.dispatch(setViewComponentProcessResult({value:ViewComponentType.table}));
    }
  }

  selectTaskStatus(status: TaskStateI) {
    this.store.dispatch(setGridDefaultState({state: status}))
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import * as processResultActions from "./process-result.actions"
import {
  executeResultTaskAction,
  loadSelectedProcessResultSuccess,
  setLoadingBookmarked,
  setLoadingCreateResult,
  setLoadingDetailProcessResult,
  setLoadingProcessResultGridData,
  setLoadingProcessResultPermissions,
  setLoadingResult,
  setLoadingResultTaskAction
} from "./process-result.actions"
import {catchError, concatMap, map, switchMap, tap, throwError} from "rxjs";
import {ProcessResultService} from "../../services/process-result.service";
import {
  GridTaskResponseI,
  MapResultResponseI,
  ProcessResultI,
  ProcessResultResponseI
} from "../../interfaces/process-result.interface";
import {TaskI, TaskStatus, TaskType} from "../../interfaces/task.interface";
import {loadSelectedTaskSuccess, setLoadingTaskPermissions} from "../task/task.actions";
import * as taskActions from "../task/task.actions";

@Injectable()
export class ProcessResultEffects {

  loadProcessResultList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.loadProcessResultList),
      switchMap((action) => {
        return this.processResultService.getProcessResultList(
          action.processId,
          action.page,
          action.limit,
          action.bookmarked,
          action.process_status,
          action.tasks,
          action.filter,
          action.globalSearch
        ).pipe(
          map((response: ProcessResultResponseI) => {
            this.store.dispatch(setLoadingResult({status: false}));
            return processResultActions.loadProcessResultListSuccess({ response: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  loadProcessResultKanban$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.loadProcessResultKanban),
      concatMap((action) => {
        return this.processResultService.getProcessResultKanban(
          action.processId,
          action.page,
          action.limit,
          action.bookmarked,
          action.process_status,
          action.tasks,
          action.filter,
          action.globalSearch
        ).pipe(
          map((response: ProcessResultResponseI) => {
            return processResultActions.loadProcessResultKanbanSuccess({
              page: +action.page,
              limit: +action.limit,
              status: action.process_status as string,
              reset: action.reset,
              response: response
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  loadProcessResultMap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.loadProcessResultMap),
      concatMap((action) => {
        return this.processResultService.getProcessResultMap(
          action.processId,
          action.page,
          action.limit,
          action.bookmarked,
          action.process_status,
          action.tasks,
          action.filter,
          action.globalSearch
        ).pipe(
          map((response: MapResultResponseI) => {
            return processResultActions.loadProcessResultMapSuccess({
              response: response
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  loadProcessResultGridData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.loadProcessResultGridData),
      concatMap((action) => {
        return this.processResultService.getProcessResultGridData({
          processId: action.processId,
          search: action.search,
          status: action.status,
          bookmarkedFilter: action.bookmarked,
          processStatusFilter: action.process_status,
          processTaskFilter: action.tasks,
          dynamicFilter: action.filter
      }).pipe(
          map((response: GridTaskResponseI) => {
            this.store.dispatch(setLoadingProcessResultGridData({status: false}));
            return processResultActions.loadProcessResultGridDataSuccess({
              response: response
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  loadProcessResul$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.loadSelectedProcessResult),
      switchMap((action) => {
        return this.processResultService.getProcessResult(
          {processId: action.processId, resultId: action.resultId}
        ).pipe(
          map((response: ProcessResultI) => {
            return processResultActions.loadSelectedProcessResultSuccess({ result: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    )
  );

  loadDetailProcessResul$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.loadDetailProcessResult),
      switchMap((action) => {
        return this.processResultService.getProcessResult(
          {processId: action.processId, resultId: action.resultId}
        ).pipe(
          map((response: ProcessResultI) => {
            this.store.dispatch(setLoadingDetailProcessResult({status: false}));
            return processResultActions.setDetailProcessResult({ result: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    )
  );

  createProcessResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.createProcessResult),
      switchMap((action) => {
        return this.processResultService.createProcessResult(action.processId).pipe(
          tap((response: ProcessResultI) => {
            // turn loading off
            this.store.dispatch(setLoadingCreateResult({status: false}));

            // Filter task to get first draft task
            const active_task = response.tasks.filter((task) => {
              return task.status.key === TaskStatus.draft && task.type !== TaskType.GROUP
            })

            //Generate notification
            this.notification.success("New process created successfully", "Success");

            //Redirect to process result view
            if (active_task?.length) {
              this.store.dispatch(loadSelectedTaskSuccess({task: active_task[0]}));
              this.store.dispatch(loadSelectedProcessResultSuccess({result: response}));
              this.router.navigate([`/process/${action.processId}/process-results/${response.id}/${active_task[0].id}`]);
            }
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    ), {dispatch: false}
  );

  changeStatusProcessResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.changeStatusProcessResult),
      concatMap((action) => {
        return this.processResultService.updateProcessResult({
          processId: action.processId,
          resultId: action.resultId,
          data: action.data
        }).pipe(
          map((response: ProcessResultI) => {
            console.log("Response => ", response);
            // return processResultActions.changeStatusProcessResultSuccess({
            //   previousStatus: action.previousStatus,
            //   index: null,
            //   result: response
            // });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 404) {
              this.notification.error(error.error, "Process result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    ),
    {dispatch: false}
  );

  bookmarkProcessResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.bookmarkProcessResult),
      switchMap((action) => {
        return this.processResultService.updateProcessResult({
          processId: action.processId,
          resultId: action.resultId,
          data: action.data
        }).pipe(
          map((response: ProcessResultI) => {
            // turn loading off
            this.store.dispatch(setLoadingBookmarked({status: false}));

            //Generate notification
            this.notification.success(
              action.data.bookmarked? "Process set as bookmarked successfully": "Process set as not bookmarked successfully" ,
              "Success"
            );

            return processResultActions.bookmarkProcessResultSuccess({ result: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            // turn loading off
            this.store.dispatch(setLoadingBookmarked({status: false}));

            switch (error.status){
              case 404:
                this.notification.error(error.error, "Process result not Found")
                break;
              case 403:
                this.router.navigate(["/login"])
                break;
              default:
                this.notification.error("An error occurred try later", "Error !!")
                break
            }
            return throwError(error);
          })
        );
      })
    )
  );

  deleteProcessResult$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.deleteProcessResult),
      switchMap((action) => {
        return this.processResultService.removeProcessResult({
          processId: action.processId,
          resultId: action.resultId,
          data: action.data
        }).pipe(
          map((response: ProcessResultResponseI) => {
            // turn loading off
            this.store.dispatch(setLoadingResult({status: false}));

            //Generate notification
            this.notification.success(
              "Process removed successfully",
              "Success"
            );

            return processResultActions.loadProcessResultListSuccess({ response: response});
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    )
  );

  executeResultTaskAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.executeResultTaskAction),
      switchMap((action) => {
        return this.processResultService.executeProcessResultAction({
          processId: action.processId,
          resultId: action.resultId,
          taskId: action.taskId,
          status: action.status
        }).pipe(
          map((result: ProcessResultI) => {
            // turn loading off
            this.store.dispatch(setLoadingResultTaskAction({status: false}));

            //Generate notification
            this.notification.success(
              "Task action executed successfully",
              "Success"
            );

            return processResultActions.executeResultTaskActionSuccess({ result: result});
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    )
  );

  patchResultTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.patchResultTask),
      switchMap((action) => {
        return this.processResultService.patchProcessResultTask({
          processId: action.processId,
          resultId: action.resultId,
          taskId: action.task.id,
          activityId: action.activity.id,
          body: action.data
        }).pipe(
          map((result: ProcessResultI) => {
            if (action.nextStatus) {
              return processResultActions.executeResultTaskAction({
                processId: action.processId,
                resultId: action.resultId,
                taskId: action.task.id,
                status: action.nextStatus
              })
            }

            return processResultActions.patchResultTaskSuccess({ result: result});
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    )
  );


  updateProcessResultPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.updateProcessResultPermissions),
      concatMap((action) => {
        return this.processResultService.updateProcessResultPermissions({
          resultId: action.resultId,
          processId: action.processId,
          body: action.data
        }).pipe(
          map((task: TaskI) => {
            return processResultActions.setLoadingProcessResultPermissions({status: false});
            // return taskActions.updateTaskPermissionsSuccess({ task: task });
          }),
          catchError((error) => {
            this.store.dispatch(setLoadingProcessResultPermissions({status: false}));
            console.log(new Date(), "ERROR:", error);
            if (error.status == 404) {
              this.notification.error(error.error, "Process Result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    )
  );

  removeProcessResultPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processResultActions.removeProcessResultPermissions),
      concatMap((action) => {
        return this.processResultService.removeProcessPermissions({
          resultId: action.resultId,
          processId: action.processId,
          body: action.data
        }).pipe(
          map((task: TaskI) => {
            return processResultActions.setLoadingProcessResultPermissions({status: false});
            // return taskActions.updateTaskPermissionsSuccess({ task: task });
          }),
          catchError((error) => {
            this.store.dispatch(setLoadingProcessResultPermissions({status: false}));
            console.log(new Date(), "ERROR:", error);
            if (error.status == 404) {
              this.notification.error(error.error, "Process Result not Found")
            }
            if (error.status == 403) {
              this.router.navigate(["/login"])
            }
            return throwError(error);
          })
        );
      })
    )
  );

	constructor(
		private actions$: Actions,
		private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private processResultService: ProcessResultService
	) {}
}

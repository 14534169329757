import { RolesUserService } from '@mangosoft/mango-layout';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { Modules } from '@mangosoft/mango-layout';
import {
  ConfigService,
  DataLoginService,
} from '@mangosoft/mango-login';
import {environment} from "../environments/environment";
import {ConfigI} from "./infrastructure/interfaces/config.interface";
import {Store} from "@ngrx/store";
import {loadConfig, loadConfigSuccess, setLoadingConfig} from "./infrastructure/store/config/config.actions";
import { setTheme } from 'ngx-bootstrap/utils'
import {AccessControlService} from "./infrastructure/services/access-control.service";
import {
  selectConfig,
  selectInitialProcess,
  selectLoadingConfig,
  selectRootUsers, selectWebsocketUrl
} from "./infrastructure/store/config/config.selector";
import {filter, Subject, take, takeUntil, withLatestFrom} from "rxjs";
import {loadPermissionsObjects} from "./infrastructure/store/permissions/permissions.actions";
import {WebsocketService} from "./infrastructure/services/websocket.service";
import {WebsocketMessageI} from "./infrastructure/interfaces/websocket.interface";
import {selectSelectedProcess} from "./infrastructure/store/process/process.selector";
import {selectSelectedProcessResult} from "./infrastructure/store/process-result/process-result.selector";
import {ProcessResultI} from "./infrastructure/interfaces/process-result.interface";
import {setViewComponentProcessResult} from "./infrastructure/store/process-result/process-result.actions";

declare var require: any;
const version = require('../../../../package.json').version;
const url = environment.accessControlAPI;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();

  version: string = '';
  url = '';
  mode = 'desktop-close';
  loadingConfig: boolean = false;

  listSidebar: Modules[] = [
    {
      id: 1,
      icon: 'account_tree',
      title: 'Process',
      route: '/process',
      status: true,
    },
    // {
    //   id: 2,
    //   icon: 'checklist',
    //   title: 'Task List',
    //   route: '/tasks',
    //   status: true,
    // },
    {
      id: 3,
      icon: "export_notes",
      title: "Import Data",
      route: "/imports",
      status: true,
    },
    {
      id: 4,
      icon: "calendar_month",
      title: "Calendar",
      route: "/calendar",
      status: true,
    }
  ];

  constructor(
    private serviceDataLogin: DataLoginService,
    private configService: ConfigService,
    private roleUserService: RolesUserService,
    private store: Store,
    private accessControlService: AccessControlService,
    private websocketService: WebsocketService
  ) {
    setTheme('bs5');

    // Load config data
    this.store.dispatch(loadConfig({
      url: `${environment.accessControlAPI}/clients/frontend?module_pk=${environment.modulePk}&module_sk=${environment.moduleSk}`,
      secret: environment.secretbKey
    }))

    // Load users and group objects
    this.store.dispatch(loadPermissionsObjects({
      url: `${environment.accessControlAPI}`
    }))


    // this.configDataCore();

    this.serviceDataLogin.saveData({
      version,
      url,
      route: '/process',
    });

    // Listen for messages in websocket files channel
    this.websocketService.messages$.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: WebsocketMessageI) => {
      this.websocketService.processWebsocketMessage(value);
    })

    // Read wss connection data and connect to websocket
    this.store.select(selectWebsocketUrl).pipe(
      filter((value) => value.server !== '' && value.channel !== ''),
      take(1)
    ).subscribe((value) => {
      if (value.server && value.channel) {
        this.websocketService.connect(
          `${value.server}?channel=${value.channel}`
        );
      }
    })


  }

  ngOnInit(): void {
    // Listen for loading splash changes
    this.store.select(selectLoadingConfig).pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => this.loadingConfig = value)

    // Listen for initial process changes
    this.store.select(selectConfig).pipe(
      takeUntil(this.destroy$),
      filter((value) => !!value.PM_API )
    ).subscribe((config) => {
      const sidebarProcessIndex = this.listSidebar.findIndex((val) => val.route === "/process")
      this.listSidebar[sidebarProcessIndex].route = `${config.PM_INITIAL_PROCESS ? 'process' : ''}/${config.PM_INITIAL_PROCESS || 'process'}/${config.PM_INITIAL_PROCESS ? 'process-results': ''}`

      // Activate import config only for root users
      const import_index = this.listSidebar.findIndex((v) => v.route==='/imports')
      if (!config.PM_ROOT_USERS.includes(this.accessControlService.getAuthUserData().username)) {
        this.listSidebar.splice(import_index,1)
      }

      //Default view from access control
      this.store.dispatch(setViewComponentProcessResult({value: config.PM_UI.default_view}))

      this.version = version;
      this.url = url;
      // this.serviceDataLogin.saveData({
      //   version,
      //   url,
      //   route: `${!!config.PM_INITIAL_PROCESS ? 'process' : ''}/${config.PM_INITIAL_PROCESS || 'process'}`,
      // });

      this.roleUserService.getPermissions.subscribe((resp) => console.log("User Permissions", resp));
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.websocketService.close()
  }
}

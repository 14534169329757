import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { InjectionToken } from "@angular/core";
import {environment} from "../../../environments/environment";
import {configFeatureKey, StateConfig} from "./config/state";
import {configReducer} from "./config/config.reducer";
import {ConfigEffects} from "./config/config.effects";
import {ProcessEffects} from "./process/process.effects";
import {processFeatureKey, StateProcess} from "./process/state";
import {processReducer} from "./process/process.reducer";
import {processResultFeatureKey, StateProcessResult} from "./process-result/state";
import {processResultReducer} from "./process-result/process-result.reducer";
import {ProcessResultEffects} from "./process-result/process-result.effects";
import {StateTask, taskFeatureKey} from "./task/state";
import {taskReducer} from "./task/task.reducer";
import {TaskEffects} from "./task/task.effects";
import {permissionsFeatureKey, StatePermissions} from "./permissions/state";
import {permissionsReducer} from "./permissions/permissions.reducer";
import {PermissionsEffects} from "./permissions/permissions.effects";
import { StateProcessScroll, processScrollFeatureKey } from "./process-scroll/state";
import { processScrollReducer } from "./process-scroll/process-scroll.reducer";
import { ProcessScrollEffects } from "./process-scroll/process-scroll.effects";
import { StateMapProcess, mapProcessFeatureKey } from "./map/state";
import { mapProcessReducer } from "./map/map.reducer";
import { MapProcessEffects } from './map/map.effects';
import {auditFeatureKey, StateAudit} from "./audit/state";
import {auditReducer} from "./audit/audit.reducer";
import {AuditEffects} from "./audit/audit.effects";

export interface AppState {
  [configFeatureKey]: StateConfig;
  [auditFeatureKey]: StateAudit;
  [processFeatureKey]: StateProcess;
  [processResultFeatureKey]: StateProcessResult,
  [processScrollFeatureKey]: StateProcessScroll,
  [taskFeatureKey]: StateTask;
  [permissionsFeatureKey]: StatePermissions;
  [mapProcessFeatureKey]: StateMapProcess;
}

export const reducers: ActionReducerMap<AppState> = {
  [configFeatureKey]: configReducer,
  [auditFeatureKey]: auditReducer,
  [processFeatureKey]: processReducer,
  [processScrollFeatureKey]: processScrollReducer,
  [processResultFeatureKey]: processResultReducer,
  [taskFeatureKey]: taskReducer,
  [permissionsFeatureKey]: permissionsReducer,
  [mapProcessFeatureKey]: mapProcessReducer,


};

export const EffectsArray: any[] = [
  ConfigEffects,
  AuditEffects,
  ProcessEffects,
  ProcessResultEffects,
  ProcessScrollEffects,
  TaskEffects,
  PermissionsEffects,
  MapProcessEffects
];

export const metaReducers: MetaReducer<AppState>[] = !environment.production ? [] : [];

export const REDUCERS_TOKEN = new InjectionToken<ActionReducerMap<AppState>>("App Reducers");
export const reducerProvider = { provide: REDUCERS_TOKEN, useValue: reducers };

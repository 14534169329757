import { createAction, props } from "@ngrx/store";
import {
  GridTaskResponseI, MapResultResponseI,
  ProcessResultFilterI,
  ProcessResultI,
  ProcessResultResponseI,
  ProcessResultTab,
  ViewComponentType
} from "../../interfaces/process-result.interface";
import {ActivityI, DetailTabs, TaskI, TaskPermissionsObjectI} from "../../interfaces/task.interface";
import {TaskStateI} from "../../interfaces/process.interface";

export const setLoadingResultTaskAction = createAction(
  "[Process Result List] Set Loading Result Task Action",
  props<{
    status: boolean
  }>()
);

export const setLoadingResult = createAction(
  "[Process Result List] Set Loading Result",
  props<{
    status: boolean
  }>()
);

export const setLoadingBookmarked = createAction(
  "[Process Result List] Set Loading Bookmarked",
  props<{
    status: boolean
  }>()
);

export const setLoadingCreateResult = createAction(
  "[Process Result List] Set Loading Create Result",
  props<{
    status: boolean
  }>()
);


export const setLoadingDetailProcessResult = createAction(
  "[Process Result List] Set Loading Detail Process Result",
  props<{
    status: boolean
  }>()
);

export const setLoadingProcessResultPermissions = createAction(
  "[Process Result List] Set Loading Process Result Permissions",
  props<{
    status: boolean
  }>()
);

export const setLoadingProcessResultMap = createAction(
  "[Process Result List] Set Loading Process Result Map",
  props<{
    status: boolean
  }>()
);


export const createProcessResult = createAction(
  "[Process Result List] Create Process Result",
  props<{
    processId: string
  }>()
);

export const createProcessResultSuccess = createAction(
  "[Process Result List] Create Process Result Success",
  props<{
    result: ProcessResultI
  }>()
);

export const loadSelectedProcessResult = createAction(
	"[Process Result List] Load Selected Process Result",
	props<{
    processId: string,
    resultId: string
  }>()
);

export const loadSelectedProcessResultSuccess = createAction(
  "[Process Result List] Load Selected Process Result Success",
  props<{
    result: ProcessResultI
  }>()
);

export const loadProcessResultList = createAction(
  "[Process Result List] Load Process Result List",
  props<{
    processId: string,
    page: number  | string,
    limit: number | string,
    bookmarked:  string | null | boolean,
    process_status: string[] |string | null,
    tasks: string[] | string | null,
    filter: any,
    globalSearch: string
  }>()
);

export const loadProcessResultListSuccess = createAction(
  "[Process Result List] Load Process Result List Success",
  props<{
    response: ProcessResultResponseI
  }>()
);

export const changeStatusProcessResult = createAction(
  "[Process Result List] Change Status Process Result",
  props<{
    previousStatus: TaskStateI,
    processId: string,
    resultId: string,
    data: ProcessResultI
  }>()
);

export const changeStatusProcessResultSuccess = createAction(
  "[Process Result List] Change Status Process Result Success",
  props<{
    previousStatus: TaskStateI,
    index: number | null,
    result: ProcessResultI
  }>()
);

export const bookmarkProcessResult = createAction(
  "[Process Result List] Bookmark Process Result",
  props<{
    processId: string,
    resultId: string,
    data: ProcessResultI
  }>()
);

export const bookmarkProcessResultSuccess = createAction(
  "[Process Result List] Bookmark Process Result Success",
  props<{
    result: ProcessResultI
  }>()
);

export const deleteProcessResult = createAction(
  "[Process Result List] Delete Process Result",
  props<{
    processId: string,
    resultId: string,
    data: {
      page: string,
      limit: string,
      bookmarked:  string | null,
      process_status: string[] |string | null,
      process_tasks: string[] | string | null,
      filter: any
    }
  }>()
);

export const executeResultTaskAction = createAction(
  "[Process Result List] Execute Result Task Action",
  props<{
    processId: string,
    resultId: string,
    taskId: string,
    status: TaskStateI
  }>()
);

export const executeResultTaskActionSuccess = createAction(
  "[Process Result List] Execute Result Task Action Success",
  props<{
    result: ProcessResultI
  }>()
);

export const patchResultTask = createAction(
  "[Process Result List] Patch Result Task",
  props<{
    processId: string,
    resultId: string,
    task: TaskI,
    activity: ActivityI,
    data: string,
    nextStatus: TaskStateI | null
  }>()
);

export const patchResultTaskSuccess = createAction(
  "[Process Result List] Patch Result Task Success",
  props<{
    result: ProcessResultI
  }>()
);

export const loadDetailProcessResult = createAction(
  "[ Process Result List ] Load Detail Process Result",
  props<{
    processId: string,
    resultId: string
  }>()
);

export const setDetailProcessResult = createAction(
  "[ Process Result List ] Set Detail Process Result",
  props<{
    result: ProcessResultI | null
  }>()
);

export const setViewComponentProcessResult = createAction(
  "[ Process Result List ] Set View Component Process Result",
  props<{
    value: ViewComponentType
  }>()
);

export const updateProcessResultPermissions = createAction(
  "[ Process Result List ] Update Process Result Permissions",
  props<{
    processId: string,
    resultId: string,
    data: TaskPermissionsObjectI
  }>()
);

export const updateProcessResultPermissionsSuccess = createAction(
  "[ Process Result List ] Update Process Result Permissions Success",
  props<{
    result: ProcessResultI
  }>()
);

export const removeProcessResultPermissions = createAction(
  "[ Process Result List ] Remove Process Result Permissions",
  props<{
    processId: string,
    resultId: string,
    data: TaskPermissionsObjectI
  }>()
);

export const setShowFilterBar = createAction(
  "[ Process Result List ] Set Show Filter Bar",
  props<{
    status: boolean
  }>()
);

export const setShowRightBar = createAction(
  "[ Process Result List ] Set Show Right Bar",
  props<{
    status: boolean
  }>()
);

export const setResultDetailsTab = createAction(
  "[ Process Result List ] Set Result Details Tab",
  props<{
    tab: DetailTabs
  }>()
);

export const setProcessResultTab = createAction(
  "[ Process Result List ] Set Process Result Tab",
  props<{
    tab: ProcessResultTab
  }>()
);

export const resetProcessResultKanban = createAction(
  "[Process Result List] Reset process result kanban",
  props<{
    status: string
  }>()
)

export const loadProcessResultKanban = createAction(
  "[Process Result List] Load process result kanban",
  props<{
    processId: string,
    page: number  | string,
    limit: number | string,
    bookmarked:  string | null | boolean,
    process_status: string[] |string | null,
    tasks: string[] | string | null,
    filter: any,
    globalSearch: string,
    reset: boolean
  }>()
)

export const loadProcessResultKanbanSuccess = createAction(
  "[Process Result List] Load Process Result Kanban Success",
  props<{
    page: number,
    limit: number,
    status: string,
    reset: boolean,
    response: ProcessResultResponseI
  }>()
);

export const loadProcessResultMap = createAction(
  "[Process Result List] Load process result map",
  props<{
    processId: string,
    page: number  | string,
    limit: number | string,
    bookmarked:  string | null | boolean,
    process_status: string[] |string | null,
    tasks: string[] | string | null,
    filter: any,
    globalSearch: string,
    reset: boolean
  }>()
)

export const resetProcessResultMap = createAction(
  "[Process Result List] Reset process result map success"
)

export const loadProcessResultMapSuccess = createAction(
  "[Process Result List] Load process result map success",
  props<{
    response: MapResultResponseI
  }>()
)

export const setProcessResultFilter = createAction(
  "[Process Result List] Set Process Result Filter",
  props<{
    filter: ProcessResultFilterI
  }>()
);

export const setProcessResultGlobalSearch = createAction(
  "[Process Result List] Set Process Result Global Search",
  props<{
    query: string
  }>()
);

export const setLoadingProcessResultGridData = createAction(
  "[Process Result List] Set Loading Process Result Grid Data",
  props<{
    status: boolean
  }>()
);

export const loadProcessResultGridData = createAction(
  "[Process Result List] Load Process Result Grid Data",
  props<{
    processId: string;
    search: string;
    status: string;
    bookmarked:  string | null | boolean,
    process_status: string[] |string | null,
    tasks: string[] | string | null,
    filter: any
  }>()
);

export const loadProcessResultGridDataSuccess = createAction(
  "[Process Result List] Load Process Result Grid Data Success",
  props<{
    response: GridTaskResponseI
  }>()
);

export const setGridDefaultState = createAction(
  "[Process Result List] Set grid default state",
  props<{
    state: TaskStateI
  }>()
);








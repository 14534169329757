<div class="w-100 d-flex flex-column overflow-hidden modal-container">
  <!-- Header -->
  <div
    class="w-100 d-flex justify-content-start align-items-center px-3"
    style="height: 50px"
  >
    <div
      class="flex-grow-1 h-100 d-flex justify-content-start align-items-center"
    >
    <span
      *ngIf="provider.value && action !== 'remove'"
      class="material-symbols-outlined text-title text-primary px-2 hover:text-complementary-color-a pointer"
      (click)="selectProvider()"
    >
      arrow_back_ios
    </span>
      <span *ngIf="!provider.value && action !== 'remove'" class="text-primary text-title">Select Provider</span>
      <span *ngIf="provider.value && action !== 'remove'" class="text-primary text-title">Provider Data</span>
    </div>
    <div
      class="h-100 d-flex justify-content-end align-items-center pointer"
      (click)="close()"
    >
    <span class="material-symbols-outlined text-neutral-color-g fw-bolder text-title">
      close
    </span>
    </div>
  </div>

  <!-- Body Add Edit -->
  <div
    *ngIf="action !== 'remove'"
    class="w-100 flex-grow-1 d-flex justify-content-start modal-container-body"
  >
    <div
      class="w-100 h-100 overflow-y-auto d-flex flex-column gap-2 px-3 py-2"
      [formGroup]="form"
    >
      <!-- Skeleton -->
      <ng-container *ngIf="loadingEmailProviders">
        <div
          class="border rounded-2 shadow-sm d-flex justify-content-center align-items-center email-provider-card
              hover:bg-neutral-color-f pointer"
          style="height: 50px"
          *ngFor="let i of [].constructor(3), let index=index"
        >
          <div
            class="skeleton-box rounded-circle mx-2"
            style="height: 30px; width: 30px"
          >
          </div>
          <div
            class="d-flex flex-column gap-1 justify-content-center  align-items-start"
          >
            <div class="skeleton-box rounded-pill" style="height: 14px; width: 150px"></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!loadingEmailProviders">
        <!-- Email Provider Field -->
        <div
          [ngClass]="{
        'd-none': provider.value
        }"
        >
          <email-provider-field
            [emailProviders]="emailProviders"
            formControlName="provider"
          ></email-provider-field>
        </div>

        <!-- Selected Email Provider-->
        <div
          class="w-100 d-flex justify-content-center align-items-center"
          [ngClass]="{
          'd-none': !provider.value || provider.value?.default
        }"
        >
          <div
            class="d-flex p-2 flex-column justify-content-center align-items-center border rounded-circle"
            style="height: 80px; width: 80px"
          >
            <div>
              <img src="{{provider.value?.icon_url}}" alt="{{provider.value?.label}}" height="30px">
            </div>
            <div>
            <span class="text-sub-title text-complementary-color-a">
              {{provider.value?.label | titlecase}}
            </span>
            </div>
          </div>
        </div>

        <!-- Cutom Provider Fields -->
        <div [class]="{
        'd-none': !provider.value?.default
      }">
          <!-- SMTP Server -->
          <div>
            <div>
              <label class="text-normal text-neutral-color-g">SMTP Server:</label>
            </div>
            <div>
              <input
                formControlName="smtp_server"
                class="px-2 border w-100 rounded-1 text-normal"
                type="text"
                style="height: 28px; outline: none"
              >
            </div>
            <div
              *ngIf="smtp_server?.errors && (smtp_server?.dirty || smtp_server?.touched)"
              class="d-flex w-100 justify-content-start align-items-start"
            >
              <span *ngIf="smtp_server?.errors?.['required']" class="text-mini text-danger lh-0">this field is required.</span>
            </div>
          </div>

          <!-- SMTP Port -->
          <div>
            <div>
              <label class="text-normal text-neutral-color-g">SMTP Port:</label>
            </div>
            <div>
              <input
                formControlName="smtp_port"
                class="px-2 border w-100 rounded-1 text-normal"
                type="number"
                style="height: 28px;
              outline: none"
              >
            </div>
            <div
              *ngIf="smtp_port?.errors && (smtp_port?.dirty || smtp_port?.touched)"
              class="d-flex w-100 justify-content-start align-items-start"
            >
              <span *ngIf="smtp_port?.errors?.['required']" class="text-mini text-danger lh-0">this field is required</span>
              <span *ngIf="smtp_port?.errors?.['min']" class="text-mini text-danger lh-0">Invalid value. Must be numeric value between 1-65535</span>
              <span *ngIf="smtp_port?.errors?.['max']" class="text-mini text-danger lh-0">Invalid value. Must be numeric value between 1-65535</span>
            </div>
          </div>

          <!-- IMAP Server -->
          <div>
            <div>
              <label class="text-normal text-neutral-color-g">IMAP Server:</label>
            </div>
            <div>
              <input
                formControlName="imap_server"
                class="px-2 border w-100 rounded-1 text-normal"
                type="text"
                style="height: 28px; outline: none"
              >
            </div>
            <div
              *ngIf="imap_server?.errors && (imap_server?.dirty || imap_server?.touched)"
              class="d-flex w-100 justify-content-start align-items-start"
            >
              <span *ngIf="imap_server?.errors?.['required']" class="text-mini text-danger lh-0">this field is required</span>
            </div>
          </div>

          <!-- IMAP Port -->
          <div>
            <div>
              <label class="text-normal text-neutral-color-g">IMAP Port:</label>
            </div>
            <div>
              <input
                formControlName="imap_port"
                class="px-2 border w-100 rounded-1 text-normal"
                type="number"
                style="height: 28px; outline: none"
              >
            </div>
            <div
              *ngIf="imap_port?.errors && (imap_port?.dirty || imap_port?.touched)"
              class="d-flex w-100 justify-content-start align-items-start"
            >
              <span *ngIf="imap_port?.errors?.['required']" class="text-mini text-danger lh-0">This field is required.</span>
              <span *ngIf="imap_port?.errors?.['min']" class="text-mini text-danger lh-0">Invalid value. Must be numeric value between 1-65535</span>
              <span *ngIf="imap_port?.errors?.['max']" class="text-mini text-danger lh-0">Invalid value. Must be numeric value between 1-65535</span>
            </div>
          </div>
        </div>

        <!-- Common Fields -->
        <div
          [ngClass]="{
          'd-none': !provider.value
        }"
        >
          <!-- Email Name -->
          <div>
            <div>
              <label class="text-normal text-neutral-color-g">Account Name:</label>
            </div>
            <div>
              <input
                formControlName="account_name"
                class="px-2 border w-100 rounded-1 text-normal"
                type="text"
                style="height: 28px; outline: none"
              >
            </div>
            <div
              *ngIf="account_name?.errors && (account_name?.dirty || account_name?.touched)"
              class="d-flex w-100 justify-content-start align-items-start"
            >
              <span *ngIf="account_name?.errors?.['required']" class="text-mini text-danger lh-0">This field is required.</span>
            </div>
          </div>

          <!-- Email -->
          <div>
            <div>
              <label class="text-normal text-neutral-color-g">Email:</label>
            </div>
            <div>
              <input
                formControlName="email"
                class="px-2 border w-100 rounded-1 text-normal"
                type="email" style="height: 28px; outline: none"
              >
            </div>
            <div
              *ngIf="email?.errors && (email?.dirty || email?.touched)"
              class="d-flex w-100 justify-content-start align-items-start"
            >
              <span *ngIf="email?.errors?.['required']" class="text-mini text-danger lh-0">This field is required.</span>
              <span *ngIf="email?.errors?.['email']" class="text-mini text-danger lh-0">Invalid email address.</span>
            </div>
          </div>

          <!-- Password -->
          <div>
            <div>
              <label class="text-normal text-neutral-color-g">Password:</label>
            </div>
            <div class="position-relative">
              <input
                formControlName="email_password"
                class="px-2 border w-100 rounded-1 text-normal"
                type="{{passwordVisibility ? 'password': 'text'}}"
                style="height: 28px; outline: none; padding-right: 30px"
              >
              <div class="h-100 position-absolute top-0 end-0 d-flex justify-content-center align-items-center"
                   style="width: 30px"
              >
                <span class="material-symbols-outlined text-title" (click)="togglePasswordVisibility()" >
                  {{passwordVisibility ? 'visibility_off' : 'visibility' }}
                </span>
              </div>

            </div>
            <div
              *ngIf="email_password?.errors && (email_password?.dirty || email_password?.touched)"
              class="d-flex w-100 justify-content-start align-items-start"
            >
              <span *ngIf="email_password?.errors?.['required']" class="text-mini text-danger lh-0">This field is required.</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="action === 'remove'"
    class="w-100 d-flex justify-content-start"
    style="height: 170px;"
  >
    <div
      class="w-100 h-100 overflow-y-auto d-flex flex-column gap-2 px-3 py-2"
    >
      <div
        class="w-100 d-flex justify-content-center align-items-center"
        [ngClass]="{
          'd-none': !provider.value || provider.value?.default
        }"
      >
        <div
          class="d-flex p-2 flex-column justify-content-center align-items-center border rounded-circle"
          style="height: 80px; width: 80px"
        >
          <div>
            <img src="{{provider.value?.icon_url}}" alt="{{provider.value?.label}}" height="30px">
          </div>
          <div>
            <span class="text-sub-title text-complementary-color-a">
              {{provider.value?.label | titlecase}}
            </span>
          </div>
        </div>
      </div>

      <div class="w-100 d-flex justify-content-center align-items-center text-center">
        <span class="text-sub-title text-neutral-color-g">Are you sure you want to remove this account?. This action can not be undone. </span>
      </div>
    </div>
  </div>



  <!-- Footer -->
  <div
    class="modal-container-footer w-100 d-flex flex-column-reverse flex-sm-row justify-content-end
          align-items-center gap-1 px-3 py-2"
  >
    <!-- Cancel button -->
    <div
      class="rounded-1 px-2 py-1 border pointer d-flex justify-content-center align-items-center pointer
             modal-container-footer-button"
      (click)="close()"
    >
      <span
        class="text-normal text-neutral-color-g"
      >
       Cancel
      </span>
    </div>

    <!-- Action button -->
    <div
      class="rounded-1 invalid-button px-2 py-1 d-flex bg-primary-color justify-content-center align-items-center pointer modal-container-footer-button"
      [ngClass]="{
      'bg-danger': action === 'remove',
      'pointer': form.valid,
      'invalid-button': !form.valid && action !== 'remove'
    }"
      (click)="save()"
    >
    <span
      *ngIf="loadingCrudUserEmailSettings"
      class="spinner-grow spinner-grow-sm text-white me-sm-1"
    >
    </span>
      <span
        *ngIf="action === 'add'"
        class="text-normal text-white"
        [ngClass]="{
        'text-neutral-color-g': !form.valid
      }"
      >
     Save
    </span>
      <span
        *ngIf="action === 'edit'"
        class="text-normal text-white"
        [ngClass]="{
        'text-neutral-color-g': !form.valid
      }"
      >
     Edit
    </span>
      <span
        *ngIf="action === 'remove'"
        class="text-normal text-white pointer"
      >
     Remove
    </span>
    </div>

  </div>
</div>





import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as processActions from "./process.actions"
import {catchError, map, switchMap, throwError} from "rxjs";
import {ProcessService} from "../../services/process.service";
import {ProcessI, ProcessResponseI} from "../../interfaces/process.interface";
import {setLoadingProcess} from "./process.actions";

@Injectable()
export class ProcessEffects {

  loadProcessList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processActions.loadProcessList),
      switchMap((action) => {
        return this.processService.getProcessList().pipe(
          map((response: ProcessResponseI) => {
            return processActions.loadProcessListSuccess({ response: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            return throwError(error);
          })
        );
      })
    )
  );

  loadSelectedProcess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(processActions.loadSelectedProcess),
      switchMap((action) => {
        return this.processService.getSelectedProcess(action.processId).pipe(
          map((response: ProcessI) => {
            this.store.dispatch(setLoadingProcess({status: false}));
            return processActions.loadSelectedProcessSuccess({ process: response });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Process not Found")
            }
            return throwError(error);
          })
        );
      })
    )
  );


	constructor(
		private actions$: Actions,
		private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private processService: ProcessService
	) {}
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import * as settingsActions from "./settings.actions";
import {catchError, map, switchMap, throwError} from "rxjs";
import {SettingsService} from "../../services/settings.service";
import {EmailProviderResponseI, EmailSettingsResponseI, UserSettingsI} from "../../interfaces/settings.interface";
import {setLoadingCrudUserEmailSettings, setLoadingEmailProviders, setLoadingSettings} from "./settings.actions";

@Injectable()
export class SettingsEffects {

  loadEmailProviders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.loadEmailProviders),
      switchMap((action) => {
        return this.settingsService.getEmailProvider().pipe(
          map((response: EmailProviderResponseI) => {
            this.store.dispatch(setLoadingEmailProviders({status: false}));
            return settingsActions.loadEmailProvidersSuccess({
              emailProviders: response.results
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            this.store.dispatch(setLoadingEmailProviders({status: false}));
            if (error.status == 400) {
              this.notification.error(error.error, "Data integrity error")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  loadUserSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.loadUserSettings),
      switchMap((action) => {
        return this.settingsService.getUserSettings({
          username: action.username
        }).pipe(
          map((response: UserSettingsI) => {
            this.store.dispatch(setLoadingSettings({status: false}));
            return settingsActions.loadUserSettingsSuccess({
              settings: response
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            this.store.dispatch(setLoadingSettings({status: false}));
            if (error.status == 400) {
              this.notification.error(error.error, "Data integrity error")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  addEmailProviderSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.addEmailProviderSetting),
      switchMap((action) => {
        return this.settingsService.addEmailProviderSetting({
          emailProvider: action.emailProvider,
          position: action.position
        }).pipe(
          map((response: EmailSettingsResponseI) => {
            if (response?.redirect_url){
              window.location.href = response.redirect_url;
            }
            this.store.dispatch(setLoadingCrudUserEmailSettings({status: false}));
            return settingsActions.addEmailProviderSettingSuccess({
              position: response.position,
              emailProvider: response.data
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            this.store.dispatch(setLoadingCrudUserEmailSettings({status: false}));
            if (error.status == 400) {
              this.notification.error(error.error, "Data integrity error")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  updateEmailProviderSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.updateEmailProviderSetting),
      switchMap((action) => {
        return this.settingsService.updateEmailProviderSetting({
          emailProvider: action.emailProvider,
          position: action.position
        }).pipe(
          map((response: EmailSettingsResponseI) => {
            this.store.dispatch(setLoadingCrudUserEmailSettings({status: false}));
            return settingsActions.updateEmailProviderSettingSuccess({
              position: response.position,
              emailProvider: response.data
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            this.store.dispatch(setLoadingCrudUserEmailSettings({status: false}));
            if (error.status == 400) {
              this.notification.error(error.error, "Data integrity error")
            }
            return throwError(error);
          })
        );
      })
    )
  );

  removeEmailProviderSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.removeEmailProviderSetting),
      switchMap((action) => {
        return this.settingsService.removeEmailProviderSetting({
          emailProvider: action.emailProvider,
          position: action.position
        }).pipe(
          map((response: EmailSettingsResponseI) => {
            this.store.dispatch(setLoadingCrudUserEmailSettings({status: false}));
            return settingsActions.removeEmailProviderSettingSuccess({
              position: response.position,
              emailProvider: response.data
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            this.store.dispatch(setLoadingCrudUserEmailSettings({status: false}));
            if (error.status == 400) {
              this.notification.error(error.error, "Data integrity error")
            }
            return throwError(error);
          })
        );
      })
    )
  );


  validateEmailProviderSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.validateEmailProviderSetting),
      switchMap((action) => {
        return this.settingsService.validateEmailProviderSetting({
          code: action.code,
          position: action.position
        }).pipe(
          map((response: EmailSettingsResponseI) => {
            return settingsActions.validateEmailProviderSettingSuccess({
              position: response.position,
              emailProvider: response.data
            });
          }),
          catchError((error) => {
            console.log(new Date(), "ERROR:", error);
            if (error.status == 400) {
              this.notification.error(error.error, "Data integrity error")
            }
            return throwError(error);
          })
        );
      })
    )
  );

	constructor(
		private actions$: Actions,
		private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private notification: ToastrService,
    private settingsService: SettingsService
	) {}
}

import {ProcessI, TaskStateI} from "./process.interface";
import {PermissionsI, PermissionsObjectI} from "./permissions.interface";
import {ProcessResultDataI} from "./process-result.interface";

export enum TaskResultTab{
  "detail"="detail",
  "activities"="activities"
}

export enum TaskActionType{
  "approve"="approve",
  "skip"="skip",
  "cancel"="cancel"
}

export enum TaskStatus {
  empty = "empty",
  draft = "draft",
  complete = "complete",
  cancel = "cancel",
  skipped="skipped"
}

export interface TaskActionsI{
  "icon": string;
  "label": string;
  "message": string;
  "status": TaskActionType;
}

export enum TaskType {
  "GROUP"="GROUP",
  "TASK"="TASK",
}

export enum ActivityType {
  "FORM"="FORM",
  "ACTIVITIES"="ACTIVITIES",
  "CALENDAR"="CALENDAR",
  "DAM_SHARED_FOLDER" = "DAM_SHARED_FOLDER",
  "CUSTOM_FORM"="CUSTOM_FORM"
}

export interface ActivityI {
  "id": string;
  "label": string;
  "type": ActivityType;
  formId?: string;
  versionId?:string;
  api?: string;
  metadata: any;
  websocketServer?: string;
  websocketChannel?: string;
  submitAction?: string;
  saveAction?: string;
}

export interface TaskI {
  "id": string;
  "resultId": string;
  "actions": TaskActionsI[],
  "label": string;
  "type": TaskType;
  "children": TaskI[];
  "parentId": string;
  "status": TaskStateI,
  "updated_at": string;
  "draftDate": string;
  "updated_by": string;
  "modalHeaderData": ProcessResultDataI;
  "bookmarked": boolean;
  "metadata": any[];
  "percentComplete": number;
  "activities": ActivityI[];
  "acl": {
    objects: PermissionsObjectI[];
    list: string[];
    read: string[];
    write: string[];
    delete: string[];
  };
  "user_permissions": PermissionsI;
  "picture": string;
  "color": string;
  process_status?: any;
  selected?: boolean;
  level?: number;
  total?: number;
}

export interface TaskPaginatorI {
  "count": number;
  "next": number | null;
  "page": number;
  "previous": number | null;
}

export interface TaskResponseI{
  "count": number;
  "page": number;
  "next": number | null;
  "previous": number | null;
  "results": TaskI[];
}

export enum DetailTabs {
  "info"="info",
  "security"="security"
}

export interface TaskPermissionsObjectI {
  object: PermissionsObjectI;
  permissions: PermissionsI;
}

import {TaskI} from "./task.interface";
import {PermissionsI, PermissionsObjectI} from "./permissions.interface";
import {TaskStateI} from "./process.interface";

export enum ProcessResultTab{
  "detail"="detail",
  "activities"="activities"
}


export enum ViewComponentType {
  grid="grid",
  table="table",
  map="map",
  kanban="kanban"
}

export enum BookmarketType {
  inactive="inactive",
  active="active"
}

export interface ProcessResultFilterI {
  tasks: string[];
  process_status: string[];
  bookmarked: boolean;
  [key: string]: string[] | string | boolean | null;
}

export interface GridListI {
  "gridCol": number | null,
  "gridValues": {
      "label": string;
      "value": string[];
    }[]
}

export interface ProcessResultModalHeaderDataI{
  "gridList": GridListI[];
  "separator": boolean;
  "url": string;
}

export interface ProcessResultDetailsDataI{
  "data": {
    "label": string;
    "value": string[];
  }[];
  "label": string;
}

export interface ProcessResultDataI {
  headerData: ProcessResultModalHeaderDataI[];
  detailsData: ProcessResultDetailsDataI[]
}

export interface ProcessResultI{
  "id": string;
  "data": any;
  "processId": string;
  "modalHeaderData": ProcessResultDataI;
  "percentComplete": number;
  "bookmarked": BookmarketType;
  "tasks": TaskI[];
  "cardData": {
    icon: string;
    value: string;
  }[],
  "mapData": {
    address: string;
    lat: string | number;
    lng: string | number;
    "postal_code": string;
    "percent_complete": number;
  },
  "properties": {
    "title": string;
    "sub_title": string;
    "description": string;
  }
  "acl": {
    objects: PermissionsObjectI[];
    list: string[];
    read: string[];
    write: string[];
    delete: string[];
  };
  "user_permissions": PermissionsI;
  "updated_by": string;
  "updated_at": string;
  "viewColumn": string;
  "viewCardColumn": string;
  "process_status": TaskStateI;
}

export interface ProcessResultResponseI{
  "count": number;
  "next": number | null;
  "page": number;
  "limit": number;
  "previous": number | null;
  "results": ProcessResultI[];
}

export interface ProcessResultPaginatorI {
  "count": number;
  "next": number | null;
  "page": number;
  "limit": number;
  "previous": number | null;
}

export interface ProcessResultKanbanI extends ProcessResultI{
  position: number;
}

export interface ProcessResultKanbanStateI {
  count: number;
  next: number | null,
  previous: number | null,
  page: number,
  result: ProcessResultI[];
}

export interface ProcessResultKanban {
  [id: string]: ProcessResultKanbanStateI
}

export interface TypeaheadItemsI {
  group: string;
  group_total: number;
  total: number;
  label: string;
}

export interface TypeaheadResponse {
  query_timeout: string;
  results: TypeaheadItemsI[];
}

export interface GridTaskResponseI {
  query_timeout: string;
  results: TaskI[];
}

export interface MapResultI {
  id: string;
  percentComplete: string;
  lat: string;
  lng: string;
  color: string;
}

export interface MapResultResponseI {
  "count": number;
  "next": number | null;
  "page": number;
  "limit": number;
  "previous": number | null;
  "results": MapResultI[];
}

import {ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ControlValueAccessor, FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR} from "@angular/forms";
import {Subject, takeUntil} from "rxjs";
import {ProcessResultService} from "../../../../infrastructure/services/process-result.service";
import {TaskI, TaskType} from "../../../../infrastructure/interfaces/task.interface";

@Component({
  selector: 'app-task-filter',
  templateUrl: './task-filter.component.html',
  styleUrls: ['./task-filter.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskFilterComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskFilterComponent implements ControlValueAccessor, OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  taskType = TaskType

  @Input() taskList: TaskI[] = [];
  @Input() selectAll!: Subject<boolean>;
  @Input() clearAll!: Subject<boolean>;

  taskTree: TaskI[] = [];
  taskLevel: TaskI[] = [];

  onChange: any = () => {};
  onTouch: any = () => {};

  value: string[] = [];

  formTaskFilter!: FormGroup

  constructor(
    private fb:FormBuilder,
    private processResultService: ProcessResultService,
  ) {
  }

  ngOnInit() {
    this.formTaskFilter = this.fb.group({
      tasks: new FormArray([])
    })



    this.taskTree = this.processResultService.createTaskTree(this.taskList);
    this.addTaskLevel(this.taskTree);

    this.taskLevel.forEach((value) => {
        this.tasks.push(
          new FormControl(value?.selected || false)
        )
      })

    this.tasks.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value: boolean[]) => {
      this.value = value
        .map((checked: boolean, i: number) => checked ? this.taskLevel[i].id: null)
        .filter((v: string|null) => v!==null) as string[];
      this.onChange(this.value);
      this.onTouch(this.value);
    })

    this.selectAll.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.tasks.controls.forEach((value, index) => value.patchValue(this.taskLevel[index].type !== TaskType.GROUP))
    })

    this.clearAll.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.tasks.controls.forEach((value) => value.patchValue(false))
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  writeValue(obj: string[]): void {
    this.value = obj;
    this.taskLevel.forEach((task, i) => {
      this.tasks.controls[i].patchValue(obj.includes(task.id) && task.type !== TaskType.GROUP);
    })
  }

  get tasks(){
    return this.formTaskFilter.get("tasks") as FormArray
  }

  addTaskLevel(taskList: TaskI[]) {
    taskList.forEach((task) => {
      if (!task.parentId) {
        this.taskLevel.push({
          ...task,
          level: 0
        })
      }else{
        const parentIndex = this.taskLevel.findIndex((val) => val.id === task.parentId)
        this.taskLevel.push({
          ...task,
          level: (this.taskLevel[parentIndex]?.level || 0) + 1
        })
      }
      this.addTaskLevel(task.children || [])
    })
  }
}

<div class="h-100 d-flex gap-2 px-3 justify-content-start align-items-center text-neutral-color-h">
  <span class="material-symbols-outlined text-sub-title" >home</span>
  <span
    *ngIf="objects.length"
    class="material-symbols-outlined text-normal" style="margin-top: 1px"
  >
    chevron_right
  </span>
  <ng-container
    *ngFor="let object of objects; let last=last"
  >
    <span
      class="text-mini hover:text-complementary-color-a pointer"
      [ngClass]="{'hover:text-complementary-color-a pointer': !last}"
      (click)="goTo(object, last)"
    >
      {{object.label}}
    </span>
    <span
      *ngIf="!last"
      class="material-symbols-outlined text-normal" style="margin-top: 1px"
    >
      chevron_right
    </span>
  </ng-container>

</div>

import {createReducer, on} from '@ngrx/store';
import {initialState} from "./state";
import * as settingsActions from "./settings.actions"

export const settingsReducer = createReducer(
	initialState,

  on(settingsActions.setLoadingSettings, (state, action) => {
    return {
      ...state,
      loadingSettings: action.status
    }
  }),

  on(settingsActions.setLoadingEmailProviders, (state, action) => {
    return {
      ...state,
      loadingEmailProviders: action.status
    }
  }),


  on(settingsActions.setLoadingCrudUserEmailSettings, (state, action) => {
    return {
      ...state,
      loadingCrudUserEmailSettings: action.status
    }
  }),

  on(settingsActions.loadEmailProvidersSuccess, (state, action) => {
    return {
      ...state,
      emailProviders: action.emailProviders
    }
  }),


  on(settingsActions.loadUserSettingsSuccess, (state, action) => {
    return {
      ...state,
      userSettings: action.settings
    }
  }),

  on(settingsActions.setSelectedSetting, (state, action) => {
    return {
      ...state,
      selectedSetting: action.setting
    }
  }),

  on(settingsActions.addEmailProviderSettingSuccess, (state, action) => {
    const emailSetting = [...state.userSettings?.email || []];
    emailSetting.splice(action.position, 0, action.emailProvider)

    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        email: emailSetting
      }
    }
  }),

  on(settingsActions.updateEmailProviderSettingSuccess, (state, action) => {
    const emailSetting = [...state.userSettings?.email || []];
    emailSetting[action.position] = action.emailProvider

    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        email: emailSetting
      }
    }
  }),

  on(settingsActions.removeEmailProviderSettingSuccess, (state, action) => {
    const emailSetting = [...state.userSettings?.email || []];
    emailSetting.splice(action.position, 1)

    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        email: emailSetting
      }
    }
  }),

  on(settingsActions.validateEmailProviderSettingSuccess, (state, action) => {
    const emailSetting = [...state.userSettings?.email || []];
    emailSetting[action.position] = action.emailProvider

    return {
      ...state,
      userSettings: {
        ...state.userSettings,
        email: emailSetting
      }
    }
  }),
);

import { createAction, props } from "@ngrx/store";
import {EmailProviderI, SettingsI, UserEmailSettingsI, UserSettingsI} from "../../interfaces/settings.interface";

export const setLoadingSettings = createAction(
  "[User Settings] Set Loading Settings",
  props<{
    status: boolean
  }>()
);

export const setLoadingEmailProviders = createAction(
  "[User Settings] Set Loading Email Providers",
  props<{
    status: boolean
  }>()
);

export const setLoadingCrudUserEmailSettings = createAction(
  "[User Settings] Set Loading Crud User Email Settings",
  props<{
    status: boolean
  }>()
);


export const loadEmailProviders = createAction(
  "[User Settings] Load Email Providers"
);

export const loadEmailProvidersSuccess = createAction(
  "[User Settings] Load Email Providers Success",
  props<{
    emailProviders: EmailProviderI[]
  }>()
);


export const setSelectedSetting = createAction(
  "[User Settings] Set selected Setting",
  props<{
    setting: SettingsI
  }>()
);

export const loadUserSettings = createAction(
  "[User Settings] Load User Setting",
  props<{
    username: string
  }>()
);

export const loadUserSettingsSuccess = createAction(
  "[User Settings] Load User Setting Success",
  props<{
    settings: UserSettingsI
  }>()
);


export const addEmailProviderSetting = createAction(
  "[User Settings] Add Email Provider Setting",
  props<{
    position: number,
    emailProvider: UserEmailSettingsI
  }>()
);

export const addEmailProviderSettingSuccess = createAction(
  "[User Settings] Add Email Provider Setting Success",
  props<{
    position: number,
    emailProvider: UserEmailSettingsI
  }>()
);

export const updateEmailProviderSetting = createAction(
  "[User Settings] Update Email Provider Setting",
  props<{
    position: number,
    emailProvider: UserEmailSettingsI
  }>()
);

export const updateEmailProviderSettingSuccess = createAction(
  "[User Settings] Update Email Provider Setting Success",
  props<{
    position: number,
    emailProvider: UserEmailSettingsI
  }>()
);

export const removeEmailProviderSetting = createAction(
  "[User Settings] Remove Email Provider Setting",
  props<{
    position: number,
    emailProvider: UserEmailSettingsI
  }>()
);

export const removeEmailProviderSettingSuccess = createAction(
  "[User Settings] Remove Email Provider Setting Success",
  props<{
    position: number,
    emailProvider: UserEmailSettingsI
  }>()
);

export const validateEmailProviderSetting = createAction(
  "[User Settings] Validate Email Provider Setting",
  props<{
    position: number,
    code: string
  }>()
);

export const validateEmailProviderSettingSuccess = createAction(
  "[User Settings] Validate Email Provider Setting Success",
  props<{
    position: number,
    emailProvider: UserEmailSettingsI
  }>()
);



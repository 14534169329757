export const environment = {
  production: false,
  name: 'dev',
  modulePk: '01HHMDTBQ27DZSPH31KTWB29JZ:module',
  moduleSk: '01HHMDTBQ2FF03KEJJQNGN2HQR:current',
  secretbKey: "47921864973276484792186497327648",
  accessControlAPI: "https://access-control.mango-api-dev.com/v1",
  dataGraphAPI: "https://api-datagraph.mango-soft.com/v3",
  titanAPI: "https://api-titan.mango-soft.com/v4/dev",
  dataGraphAPIKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRpdGFuX0FQSSIsIm1vZHVsZSI6Ik1BTkdPX1RJVEFOIiwiaWF0IjoxNTE3NTE0OTM5LCJleHAiOjE1Mzk2MzMzMzl9.VNvGAxeCNDffmDhzJ-uWsxxghfqIubamfLVyUe8uYmo",
  urlIncidences: "https://ccf-webcomponents.mango-soft.com/playbyplay/index.html#/"
};

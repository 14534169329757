import {
  MapResultI,
  ProcessResultFilterI,
  ProcessResultI, ProcessResultKanban,
  ProcessResultPaginatorI,
  ProcessResultTab,
  ViewComponentType
} from "../../interfaces/process-result.interface";
import {DetailTabs, TaskI} from "../../interfaces/task.interface";
import {TaskStateI} from "../../interfaces/process.interface";


export const processResultFeatureKey = 'processResult';

export const itemsPerPageList = [
  {value: "10", label: "10"},
  {value: "50", label: "50"},
  {value: "100", label: "100"},
]

export const initialProcessResultPaginator = {
  "count": 0,
  "page": 1,
  "limit": 10,
  "previous": null,
  "next": null
}

export const initialProcessStatusFilter: ProcessResultFilterI = {
  tasks: [],
  process_status: [],
  bookmarked: false
}

export interface StateProcessResult {
  paginator: ProcessResultPaginatorI;
  processResultList: ProcessResultI[];
  processResultMap: {
    "count": number,
    "page": number,
    "loaded": number,
    "valid": number,
    "result": MapResultI[][]
  };
  selectedProcessResult: ProcessResultI | null;
  detailProcessResult: ProcessResultI | null;
  loadingResults: boolean;
  loadingBookmarked: boolean;
  loadingCreateResult: boolean;
  loadingResultTaskAction: boolean;
  loadingProcessResultPermissions: boolean;
  loadingProcessResultGridData: boolean;
  loadingDetailProcessResult: boolean;
  loadingProcessResultMap: boolean;
  showFilterBar: boolean;
  showRightBar: boolean;
  processResultTab: ProcessResultTab;
  detailTab: DetailTabs;
  viewComponent: ViewComponentType;
  processResultKanban: ProcessResultKanban | null;
  processResultFilter: ProcessResultFilterI;
  globalSearch: string;
  gridData: TaskI[];
  gridDefaultState: TaskStateI | null;
}

//ESTADO INICIAL
export const initialState: StateProcessResult = {
  paginator: initialProcessResultPaginator,
  processResultList: [],
  processResultMap: {count: 0, loaded: 0, page: 0, valid: 0, result: []},
  selectedProcessResult: null,
  detailProcessResult: null,
  loadingResults: true,
  loadingBookmarked: false,
  loadingCreateResult: false,
  loadingResultTaskAction: false,
  loadingProcessResultPermissions: false,
  loadingProcessResultGridData: false,
  loadingDetailProcessResult: false,
  loadingProcessResultMap: true,
  showFilterBar: false,
  showRightBar: false,
  processResultTab: ProcessResultTab.detail,
  detailTab: DetailTabs.info,
  viewComponent: ViewComponentType.grid,
  processResultKanban: null,
  processResultFilter: initialProcessStatusFilter,
  globalSearch: "",
  gridData: [],
  gridDefaultState: null
};
